import imagesLoaded from 'imagesloaded';
import VanillaLightbox from 'vanilla-lightbox';
import MiniMasonry from 'minimasonry';
import Splide from '@splidejs/splide';

const vanillaLightbox = new VanillaLightbox;



if (document.querySelector('.masonry')) {
	var grid = document.querySelector('.masonry');

  var msnry = new MiniMasonry({
		container: grid,
		gutter: 10,
		surroundingGutter: false,
		ultimateGutter: 10,
		baseWidth: 320
	});

	/*
	var msnry = new Masonry(grid, {
		itemSelector: '.masonry__brick',
		columnWidth: '.masonry__brick--sizer',
		percentPosition: true
	})
	*/

	imagesLoaded(grid).on('progress', function() {
		msnry.layout();
	})
}

Array.from(document.getElementsByClassName('splide')).forEach(
	(el) => {
		new Splide(el).mount();
	}
)

document.getElementsByTagName('body')[0].classList.remove('no-js');

